.mf2 {
  min-height: 100vh;
  overflow: hidden;

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  &--photo {
    // border: solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  &--content {
    &__body {
      margin-bottom: 1em;
    }
  }

  &--list {
    margin-top: 2em;
  }

  &--item {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    gap: 0.5em;
    font-weight: 700;

    &__bullet {
      height: 1em;
      width: 1em;
      background-color: var(--color-gray);
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .mf2 {
    margin-top: 10vh;
    min-height: 0;
    padding-bottom: calc(#{$nav-height} * 1);

    .container {
      display: flex;
      justify-content: center;
    }

    &--photo {
      // width: 38%;
      flex-basis: 50%;
    }

    &--content {
      // width: 62%;
      flex-basis: 50%;
    }
  }
}
