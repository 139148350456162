// Colors
:root {
  --color-main: #f4f3f1;
  --color-dark: #817567;
  --color-gold: #b59e80;
  --color-medium: #e3d8ca;
  --color-font: #8c7455;
  --color-gray: #88837d;
  --color-pinkish: #faf7f6;
  --color-band: #f0e4d8;
}

$nav-height: 3.5em;
$container-maxwidth: 1440px;
$color-band: rgba(#f0e4d8, 0.5);

$bp-small: 24.15;
$bp-medium: 48; // 768
$bp-large: 60.25; // 964
$bp-xlarge: 75; // 1200
