.machine {
  position: relative;
  height: 160vh;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  &--img {
    margin-top: 5vh;
    height: 70vh;

    img {
      @include fitimg;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .machine {
    height: calc(90vh - #{$nav-height});
    margin-top: 10vh;

    .container {
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      gap: 4em;
    }
  }
}
