body {
  background-color: var(--color-main);
  font-family: 'Quicksand', sans-serif;
  color: var(--color-font);
  // overflow-x: hidden;
}

.container {
  margin: 0 auto;
  max-width: $container-maxwidth;
  height: 100%;
  padding: 0 2rem;
}

.iframeContainer {
  position: relative;
  // border: solid red;
  width: 100%;
  height: 50vh;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  margin: 0 auto;
  // padding: 42.86% 0 0 0;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 177.77vh;
    height: 100%;
    // width: 100%;
    // height: 56.25%;
    // min-width: 177.77vh;
    // width: 100vw;
    // height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    // min-height: 100vh;
    // min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    z-index: 200;
  }
}

ul {
  list-style: none;
}

.title {
  font-family: 'Forum', serif;
  font-size: 3em;
  text-align: center;

  span {
    font-size: 0.7em;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(black, 0.4);
  background-color: rgba(#b59e80, 0.4);
  backdrop-filter: blur(6px);
  z-index: 690;
}

@media screen and (max-height: 600px) {
  body {
    font-size: 14px;
  }
}
