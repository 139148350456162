.cartridges {
  .container {
    height: auto;
  }

  &--section {
    min-height: 100vh;
    padding-top: calc(#{$nav-height} * 0.5);
    padding-bottom: calc(#{$nav-height} * 1);

    &_micro {
      background-color: var(--color-pinkish);
    }

    &_macro {
    }
  }

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  &--body {
    &:first-of-type {
      margin-bottom: 1em;
    }
  }

  &--list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3em;
    row-gap: 2em;
  }

  &--item {
    &__photo {
      img {
        @include fitimg;
      }
    }

    &__name {
      text-align: center;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .cartridges {
    display: flex;

    &--section {
      width: 50%;
    }

    &--item {
      &__photo {
        overflow: hidden;
        height: 150px;

        img {
          object-fit: contain;
        }
      }

      &__name {
        margin-top: 1em;
      }
    }
  }
}
