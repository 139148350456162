.footer {
  // mheight: 40vh;
  background-color: var(--color-gray);
  padding-top: 2em;
  padding-bottom: 4em;
  color: var(--color-main);

  &--logo {
    width: 10em;

    img {
      @include fitimg;
    }
  }

  &--text {
    font-size: 0.9em;
    margin-top: 1em;
  }

  &--list {
    margin-top: 1em;

    a {
      text-decoration: none;
      color: var(--color-main);
      display: flex;
      align-items: center;
      gap: 1.5em;
    }
  }

  &--item {
    margin-bottom: 1em;

    &__icon {
      height: 2em;
      width: 2em;
    }

    &__text {
      font-weight: 700;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .footer {
    .container {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
    }

    &--logo {
      width: 15em;
      grid-row: 1 / 2;
      grid-column: 1 / 2;

      img {
        object-fit: contain;
      }
    }

    &--list {
      margin-top: 0;
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }

    &--text {
      width: 30em;
      font-size: 1em;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
