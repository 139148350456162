.form {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  transform: translateX(100%);
  background-color: var(--color-main);
  color: var(--color-font);
  padding-top: calc(#{$nav-height} * 1);
  z-index: 700;

  .body {
    margin-top: 1em;
  }

  .closeBtn {
    position: absolute;
    top: 1em;
    right: 2em;
    height: 1.25em;
    width: 1.25em;
    cursor: pointer;
  }

  form {
    margin-top: 1em;
  }

  &--inputs {
    position: relative;
    height: 8em;
  }

  &--controls {
    margin-top: 1em;
    position: relative;

    &__row {
      position: relative;
      height: 3em;
    }
  }

  &--inputGroup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 700;

    &__label {
      font-weight: 600;
    }

    &__input {
      border: solid thin;
      border-color: var(--color-brand-700);
      font-family: "Rajdhani", sans-serif;
      padding: 0.5em;
      font-size: 1em;

      &.error {
        border: solid 2px;
        border-color: rgb(227, 53, 53);
      }
    }
  }

  #ig_name {
    position: absolute;
    opacity: 1;
    z-index: 701;
  }

  &--submit {
    margin-top: 2em;
  }

  #submitBtn {
    display: none;
  }

  &--moveBtn {
    background-color: transparent;
    outline: none;
    border: none;
    @include morelink(dark);
    position: absolute;
    bottom: 0;

    &_prev {
      display: none;
      opacity: 0;
      left: 0;
    }

    &_next {
      right: 0;
    }
  }

  &--error {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3em;
    background-color: rgb(227, 53, 53);
    color: var(--color-main);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--success {
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 5em;
    transform: translateY(-50%);
    background-color: #92aa94;
    color: var(--color-main);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--submit {
    background-color: var(--color-font);
    color: var(--color-main);
    padding: 0.5em 1em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 1.125em;
    margin: 0 auto;
    margin-top: 1em;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .form {
    max-width: calc(#{$bp-small}* 1em);
  }
}
