@mixin fitimg {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@mixin morelink($mode, $position: null) {
  position: relative;
  display: inline-block;
  letter-spacing: 2px;
  margin-top: 3em;
  font-weight: 500;
  font-size: 1em;
  text-decoration: none;
  padding: 0.5em 0;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;

  .border {
    position: absolute;
    bottom: 0;
    left: -35px;
    height: 3px;
    width: 35px;
    background-color: var(--color-secondary-400);
    transition: all 0.3s;
  }

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
  }

  &::after {
    content: attr(data-content);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0.5em 0;
    transition: 0.3s;
    z-index: 888;
  }

  p {
    transition: 0.3s;
    opacity: 1;
  }

  &:hover {
    p {
      opacity: 0;
      transform: translateY(-24px);
    }

    &::after {
      opacity: 1;
      visibility: visible;
      top: 0;
    }

    .border {
      left: 100%;
    }
  }

  @if $mode == dark {
    color: var(--color-brand-700);
    border-color: var(--color-brand-700);

    &::before {
      background-color: var(--color-brand-700);
    }
  }
  @if $mode == light {
    color: var(--color-light);
    border-color: var(--color-light);

    &::before {
      background-color: var(--color-light);
    }
  }

  @if $position == center {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
