.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  background-color: var(--color-main);
  z-index: 66;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--brand {
    width: 8em;

    img {
      @include fitimg;
    }
  }

  &--btn {
    background-color: var(--color-gold);
    color: var(--color-main);
    padding: 0.5em 1em;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &--burger {
    cursor: pointer;
    z-index: 99;

    div {
      background-color: var(--color-font);
      width: 25px;
      height: 3px;
      margin: 3px;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .nav {
    // border-top: solid orange;
  }
}
@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .nav {
    // border-top: solid green;
  }
}
@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  .nav {
    // border-top: solid red;
  }
}
