.resultados {
  overflow: hidden;
  min-height: 100vh;
  margin-top: 5vh;
  padding-bottom: calc(#{$nav-height} * 2);

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  two-up {
    overflow: hidden;
    max-width: 800px;
    height: 500px;

    .twopart {
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        max-height: 500px;
      }
    }
  }

  .two {
    &--container {
      margin-bottom: 2em;
    }

    &--title {
      font-family: 'Forum', serif;
      font-size: 2em;
      text-align: center;
      background-color: var(--color-medium);
    }
  }

  &--btnRow {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    gap: 4em;
  }

  &--btn {
    cursor: pointer;
    user-select: none;
    height: 4em;
    background-color: transparent;
    border: none;

    img {
      @include fitimg;
    }
  }
}

@media screen and (max-height: 600px) {
  .resultados {
    two-up {
      max-height: 370px;

      img {
        max-height: 370px;
      }
    }
  }
}

@media screen and (max-height: 680px) {
  .resultados {
    two-up {
      max-height: 400px;

      img {
        max-height: 400px;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .resultados {
    .container {
      display: flex;
      justify-content: center;
      gap: 3em;
    }

    .two {
      &--container {
        flex-basis: 50%;
      }
    }
  }
}
