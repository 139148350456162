.intro {
  margin-top: 10vh;
  min-height: 100vh;
  color: var(--color-dark);
  background-color: var(--color-medium);
  padding-top: 2em;
  // padding-bottom: 2em;

  &--presenting {
    margin-top: 2em;
    margin-bottom: 1em;
    font-weight: 700;
    text-align: center;
  }

  &--title {
    font-family: 'Forum', serif;
    font-size: 2.5em;
  }

  &--subtitle {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1;
  }

  &--grid__photo {
    // border: solid green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transform-origin: left;
    }
  }

  &--reveal {
    position: relative;
    visibility: hidden;
    width: 80%;
    height: 80%;
    max-width: 500px;
    overflow: hidden;
  }

  &--grid__stats {
    margin-top: 1em;
  }

  &--feature {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1em;

    &__square {
      height: 2em;
      width: 2em;
      background-color: var(--color-gold);
    }

    &__textContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1em;
      padding: 0 1em;
      background-color: var(--color-gold);
      color: white;
    }

    &__number {
      font-size: 2.5em;
      font-weight: 700;
    }

    &__name {
      font-size: 1.5em;
      font-weight: 300;
    }
  }

  .cofepris {
    margin-top: 2em;
    color: white;
    background-color: var(--color-dark);
    text-align: center;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .intro {
    margin-top: 0;
    position: relative;

    &--grid {
      margin-top: 3em;
      display: grid;
      grid-template-columns: 62% 38%;
      grid-template-rows: min-content;

      &__title {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      &__photo {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      &__stats {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
    }

    &--presenting {
      font-size: 1.25em;
    }

    &--title {
      font-size: 3.5em;
    }

    &--subtitle {
      font-size: 2em;
    }

    &--feature {
      justify-content: flex-end;
      gap: 1em;
    }

    .cofepris {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}
