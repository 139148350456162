.ventajas {
  min-height: 100vh;
  background-color: var(--color-gray);
  color: var(--color-main);
  border-top: solid transparent;

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  &--item {
    font-size: 1.5em;
    font-weight: 500;
    padding: 1em 0;
    border-bottom: solid var(--color-main) 1px;
    overflow: hidden;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: solid green;
    }

    &__text {
      width: 10em;
    }

    &__detail {
      height: 0;
      font-size: 1rem;
      overflow: hidden;
    }
  }

  &--cross {
    cursor: pointer;
    position: relative;

    &__target {
      position: absolute;
      top: 0;
      left: 0;
      height: 2em;
      width: 100%;
      transform: translateY(-50%);
    }

    &__line {
      width: 40px;
      height: 3px;
      background-color: var(--color-main);
    }

    &__line1 {
      transform: rotate(-90deg);
    }

    &__line2 {
      transform: translateY(-3px);
    }
  }

  &--photos {
    margin-top: 10vh;

    &__item {
      height: 50%;
      margin-bottom: 1em;

      img {
        @include fitimg;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .ventajas {
    margin-top: 10vh;

    .container {
      display: flex;
      justify-content: space-between;
    }

    &--list {
      flex-basis: 55%;
    }

    &--photos {
      margin-top: 0;
      margin-left: 3em;
      flex-basis: 45%;
    }

    &--item {
      &__text {
        width: auto;
      }
    }
  }
}
