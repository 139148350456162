.hero {
  margin-top: $nav-height;
  height: 100vh;
  height: calc(100vh - #{$nav-height});
  position: relative;
  // border-bottom: solid var(--color-gold) 10px;

  .container {
    margin-top: 3em;
    height: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    // border: solid orange;
  }

  &--title {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.125;
    color: var(--color-dark);
  }

  &--cta {
    margin-top: 1em;
    background-color: var(--color-gold);
    color: var(--color-main);
    padding: 1em 1em;
    font-size: 1em;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
  }
}

@media screen and (max-width: 500px) and (min-height: 845px) {
  .hero {
    .container {
      margin-top: 4em;
    }

    &--title {
      // font-size: 2.25em;
      font-size: 2em;
      // color: red;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .hero {
    .iframeContainer {
      // border: solid green;
      padding: 42.86% 0 0 0;
      height: 100%;

      iframe {
        min-height: 100%;
        min-width: 100%;
        height: 56.26vw;
        width: 177.7777vh;
      }
    }

    .container {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &--title {
      color: var(--color-main);
      font-size: 3em;
      position: absolute;
      bottom: 5em;
      left: 2em;
      width: 12em;
      font-weight: 700;
    }

    &--cta {
      position: absolute;
      bottom: 5em;
      font-size: 1.5em;
      padding: 0.5em 1em;
      left: 4em;
      width: 15em;
    }
  }
}
