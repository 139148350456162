.tratamientos {
  min-height: 100vh;
  margin-top: 10vh;
  padding-bottom: $nav-height;
  overflow: hidden;

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  &--list {
    margin-top: 1em;
  }

  &--item {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    gap: 0.5em;
    font-weight: 700;

    &__bullet {
      height: 1em;
      width: 1em;
      background-color: var(--color-gray);
    }
  }

  &--photos {
    height: 100vh;
    margin-top: 10vh;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 31%;

      img {
        @include fitimg;
      }
    }
  }

  &--reveal {
    position: relative;
    visibility: hidden;
    width: 100%;
    height: 100%;
    max-width: 500px;
    overflow: hidden;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .tratamientos {
    display: flex;
    flex-direction: row-reverse;
    // justify-content: space-between;

    &.reverse {
      flex-direction: row;

      .tratamientos--photos {
        margin-left: 2em;
        // border: solid green;
      }
    }

    &--content {
      flex-basis: 38%;
    }

    &--title {
      margin-top: 0;
    }

    &--photos {
      margin-top: 0;
      flex-basis: 62%;

      .container {
        position: relative;
      }

      &__item {
        position: absolute;

        &:nth-child(1) {
          top: 0;
          left: 0;
          height: 50%;
        }
        &:nth-child(2) {
          top: 40%;
          left: 25%;
          height: 40%;
        }
        &:nth-child(3) {
          top: 75%;
          left: 15%;
        }
      }
    }
  }
}
