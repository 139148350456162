.effects {
  background-color: var(--color-pinkish);
  min-height: 100vh;
  // border: solid blue;
  overflow: hidden;

  &--title {
    background-color: $color-band;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 0;
  }

  &--item {
    text-align: center;
    padding: 2em 1em;
    background-color: var(--color-main);
    margin-bottom: 3em;

    &:first-of-type {
      background-color: #faf1e8;
    }

    &:last-of-type {
      background-color: #fff4e9;
    }

    &__name {
      font-family: 'Forum', serif;
      font-size: 3em;
      letter-spacing: 3px;
    }

    &__strong {
      font-weight: 700;
      margin-top: 0.5em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .effects {
    min-height: 0;
    padding: calc(#{$nav-height}* 2) 0;

    &--list {
      display: flex;
      justify-content: space-between;
    }

    &--item {
      flex-basis: 30%;
    }
  }
}
