.move {
  // border: solid blue;
  margin-top: 10vh;
  min-height: 100vh;
  padding-top: 2em;
  padding-bottom: 2em;
  color: var(--color-font);
  overflow: hidden;

  &--grid {
    &__photo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1em;

      img {
        @include fitimg;
      }
    }

    &__text2 {
      margin-top: 2em;
    }
  }

  &--reveal {
    position: relative;
    visibility: hidden;
    width: 80%;
    height: 80%;
    // max-width: 500px;
    overflow: hidden;
  }

  &--application {
    background-color: var(--color-gray);
    position: relative;
    margin-top: 1em;
    padding: 0.5em 0;

    p {
      color: white;
      font-size: 2em;
      text-align: center;

      &:first-of-type {
        margin-bottom: 0.5em;
      }
    }

    #en {
      position: absolute;
      left: 1em;
      top: 50%;
      font-weight: 300;
      transform: translateY(-50%);
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .move {
    min-height: 0;

    &--grid {
      display: grid;
      grid-template-columns: 38% 62%;

      &__photo {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        margin-top: 0;
      }

      &__text1 {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        // border: solid green;
      }

      &__text2 {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        margin-top: 0;
      }
    }

    &--reveal {
      height: 100%;
    }
  }
}
