.contact {
  background-color: var(--color-medium);
  padding: 5vh 0;
  // border: solid blue;

  .title {
    letter-spacing: 4px;
  }

  &--cta {
    display: block;
    margin: 0 auto;
    margin-top: 1em;
    background-color: var(--color-gold);
    color: var(--color-main);
    padding: 1em 1em;
    font-size: 1em;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
  }
}
